module.exports = [
  'default',
  // don't change the order of the languages unless you know what you are doing
  'en-us',
  'es-es',
  'sv-se',
  'de-de',
  'fr-fr',
  'it-it',
  'hu-hu',
  'sr-rs',
  'hr-hr',
  'mk-mk',
  'pl-pl',
  'ro-ro',
  'en-se',
  'en-ae',
  'en-rs',
  'en-hu',
  'en-ie',
  'en-it',
  'en-ke',
  'en-de',
  'en-fr',
  'en-es',
  'en-hr',
  'en-pl',
  'en-ro',
  'en-au',
  'en-ca',
  'en-cz',
  'en-ch',
  'en-gb',
  'en-pa',
  'en-pe',
  'es-pa',
  'es-pe',
  'nl-nl',
  'en-nl',
  'sk-sk',
  'en-sk',
  'pt-pt',
  'en-pt',
]
